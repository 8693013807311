<template>
  <app-layout>
    <div class="flex flex-col items-center pb-32">
      <div class="w-full px-6 pt-6 2xl:w-3/5 2xl:px-0">
        <div class="w-full mb-4 space-y-4">
          <div class="flex flex-col md:flex-row py-4">
            <div class="flex flex-row items-center">
              <sa-icon-button
                with-bg
                iconClass="bx bx-left-arrow-alt"
                @click="$router.push({ name: 'home.index' })" />
                <span class="mx-2 text-lg font-bold">
                  Cerrar Dia
                </span>
            </div>
            <div class="flex flex-row items-center ml-auto">
              <el-button class="el-button--warning" @click="print">
                <span>Imprimir</span>
              </el-button>
            </div>
          </div>
          <div id="print-area" class="w-full mb-4 space-y-4 relative">
            <div class="items-center text-center py-2 justify-between">
              <div class="w-4/5 m-auto justify-center align-center">
                <div class="w-full flex flex-row items-center justify-center">
                  <img src="@/assets/img/mini-responsive-samii.png" v-if="!logo" alt="" width="70px">
                  <img :src="logo" v-if="logo" alt="" style="width: 200px !important; height: 100px !important;">
                </div>
                <div class="w-full flex flex-row items-center justify-center">
                  <span class="font-bold text-gray-600 text-3xl font-headline ml-1">
                    {{ $store.state.business.name || 'Fixme up' }}
                  </span>
                </div>
              </div>
              <div class="w-full lg:w-1/3 m-auto justify-center align-center">
                <div class="w-full">
                  <div class="border bg-black border-black text-white outline-none">
                    Cerrar dia
                  </div>
                  <div class="border">
                    <div>
                      <strong>{{ todayDate }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div v-for="payment_method in totalPaymentMethod" :key="payment_method.name" class="mb-2">
                <div class="w-full border p-2 flex flex-row justify-between bg-gray-200">
                  <strong class="text-lg uppercase">
                    {{ payment_method.name }}
                  </strong>
                  <strong class="text-lg uppercase">
                    {{ moneyFormat(payment_method.total) }}
                  </strong>
                </div>
                <div class="w-full border p-2">
                  <div v-for="order in payment_method.orders" :key="order.id" class="border-b">
                    <div class="w-full flex flex-col lg:flex-row">
                      <div class="w-full lg:w-2/12">
                        <a @click="$router.push({ name: 'orders.read', params: { id: order.id } })">
                          <strong>#: </strong>
                          {{ order.reference }}
                        </a>
                      </div>
                      <div class="w-full lg:w-3/12">
                        <strong>Tipo: </strong>
                        {{ order.type.label }}
                      </div>
                    </div>
                    <div class="w-full text-justify py-2">
                      <div class="w-full">
                        <div
                          class="w-full"
                          v-if="order.type.value === OrderTypesEnum.Repairs || order.type.value === OrderTypesEnum.Mixed">
                          <strong>Dispositivo: </strong>
                          <span>{{ `${order.orderDevices[0].deviceModel} ${order.orderDevices[0].deviceBrand}` }}</span>
                        </div>
                        <div
                          class="w-full"
                          v-if="order.orderDevices.length
                          && order.orderDevices[0].diagnosticNotes
                          && (order.type.value === OrderTypesEnum.Repairs || order.type.value === OrderTypesEnum.Mixed)">
                          <strong>Descripcion del problema: </strong>
                          <span>{{ order.orderDevices[0].diagnosticNotes }}</span>
                        </div>
                        <div class="w-full" v-if="order.type.value === OrderTypesEnum.Sales || order.type.value === OrderTypesEnum.Mixed">
                          <strong>Productos: </strong>
                          <ul style="column-count: 4">
                            <li v-for="product in order.orderProducts" :key="product.id">
                              - {{ product.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2 lg:w-1/5 ml-auto text-right">
              <div class="w-full flex flex-row justify-between">
                <div>
                  Total:
                </div>
                <div class="text-right">
                  {{ moneyFormat(totalSales) }}
                </div>
              </div>
              <div class="w-full flex flex-row justify-between">
                <div>
                  Depositos:
                </div>
                <div class="text-right">
                  {{ moneyFormat(totalAdvanceAmount) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>
<style scoped>
@media print {
  body *:not(#print-area):not(#print-area *) {
    visibility: hidden;
  }
  #print-area {
    position: absolute;
    visibility: visible;
    top: 0;
    left: 0;
    -webkit-print-color-adjust: exact;
  }
  #print-area * {
    overflow-x: hidden;
  }
}
</style>
<script>
import numbers from '@/utils/numbers';
import OrderService from '@/services/OrderService';
import BusinessGetters from '@/store/modules/business/getters';
import { getOrderType } from '@/utils/orders';
import { OrderTypesEnum } from '@/constants';

export default {
  name: 'CloseDayIndex',
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
  },
  data () {
    return {
      totalSales: 0,
      totalPaymentMethod: [],
      totalAdvanceAmount: 0,
    }
  },
  mounted() {
    this.load();
  },
  computed: {
    OrderTypesEnum() {
      return OrderTypesEnum;
    },
    logo() {
      return BusinessGetters.logo();
    },
    todayDate() {
      const format = `${this.$store.state.business.dateFormat?.toUpperCase()} hh:mm:ss A` ?? "MM/DD/YYYY hh:mm:ss A";
      return this.$moment(new Date()).format(format);
    },
  },
  methods: {
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    print() {
      window.print();
    },
    async load() {
      const dailySales = await OrderService.getDailySales({ date: this.$moment(new Date()).format('MM/DD/YYYY') });
      this.totalSales = dailySales.totalSales;
      this.totalPaymentMethod = dailySales.totalPaymentMethod.map(({ orders, paymentMethod, total }) => ({
        name: paymentMethod,
        total: total,
        orders: orders.map((order) => ({
          ...order,
          type: getOrderType(order),
        })),
      }));
      this.totalAdvanceAmount = dailySales.totalAdvanceAmount;
    },
  },
}
</script>
