import { OrderTypesEnum, OrderTypes } from '@/constants';

export const getOrderType = (order) => {
  const isRepair = order.orderDevices?.length || false;
  const isSale = order.orderProducts?.length || false;

  if (isRepair && isSale) return OrderTypes[OrderTypesEnum.Mixed];
  if (isRepair) return OrderTypes[OrderTypesEnum.Repairs];
  if (isSale) return OrderTypes[OrderTypesEnum.Sales];
  return OrderTypes[OrderTypesEnum.None];
};
